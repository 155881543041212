import logo from './assets/star_grails.png';
import video from './assets/StarGrails.mp4';
import bg from './assets/bg.svg';
import './App.css';
import ReactPlayer from 'react-player';

function App() {
  return (
    <div
      style={{
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        width: '100%'
      }}
    >
      <ReactPlayer
        width={'100vw'}
        height={'100vh'}
        loop={true}
        playsinline
        autoplay
        muted={true}
        playing
        url={[ { src: video } ]}
      />;
    </div>
  );
}

export default App;
